























































































































































































import { RouterNames } from "@/router/routernames";
import { alimentos_fichaDto } from "@/shared/dtos/visualizacion_dieta/base_datos_alimentos/alimentos_fichaDto";
import { alimento } from "@/shared/dtos/visualizacion_dieta/alimento";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import { Component, Vue } from "vue-property-decorator";
import { ssmMessageService } from "@/shared/services/message-service";

@Component({
  $_veeValidate: { validator: "new" },
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    EstructuraBasica: () =>
      import("@/views/base_alimentos/estructura_basica_ficha.vue"),
  },
})
export default class Alimentos_ficha extends Vue {
  public inicializar = false;
  created() {
    if (this.is_new()) {
      alimentoModule.onGetalimentoFichaReset(new alimentos_fichaDto());
      this.inicializar = true;
    } else {
      alimentoModule
        .getalimentoficha(Number.parseInt(this.$route.params.id))
        .then(() => (this.inicializar = true));
    }
    alimentoModule.gettemporadas();
    alimentoModule.getgrupos_alimentos();
  }

  public get datasource() {
    return alimentoModule.alimento_ficha;
  }

  public get editable() {
    if (this.is_new()) {
      return true;
    }
    return !this.sistema;
  }

  public get sistema() {
    return this.datasource.sistema;
  }

  public get temporadas() {
    return alimentoModule.temporadas;
  }

  public get grupos_alimentos() {
    return alimentoModule.grupos_alimentos;
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }
  public cancelar() {
    this.$router.push({ name: RouterNames.alimentolista });
  }

  public eliminar() {
    if (this.is_new()) {
      ssmMessageService.toastinfo(
        "No se puede eliminar un alimento que todavía no está creado"
      );
      return;
    }
    if (this.datasource.sistema) {
      ssmMessageService.toastinfo(
        "No se puede eliminar un alimento que es del sistema"
      );
      return;
    }
    alimentoModule
      .eliminar_ficha_alimento(this.datasource.id)
      .then((x) => this.$router.push({ name: RouterNames.alimentolista }));
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          alimentoModule
            .guardar_ficha_alimento(this.datasource)
            .then((x) =>
              this.$router.push({ name: RouterNames.alimentolista })
            );
        } else {
          alimentoModule
            .modificar_ficha_alimento(this.datasource)
            .then((x) =>
              this.$router.push({ name: RouterNames.alimentolista })
            );
        }
      }
    });
    // suplementoModule.guardarinvetario(this.datasource);
  }

  public clonar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.datasource.nombre = this.datasource.nombre + " - clonado";
        alimentoModule
          .guardar_ficha_alimento(this.datasource)
          .then((x) => this.$router.push({ name: RouterNames.alimentolista }));
      }
    });
    // suplementoModule.guardarinvetario(this.datasource);
  }
}
